import React, { useEffect, useMemo, useState } from 'react';

// @ts-ignore
import { useAtom } from 'jotai';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AiOutlineHome, AiOutlineUser } from 'react-icons/ai';
import { BiMessageAlt, BiStore } from 'react-icons/bi';
import {
  HiOutlinePlus,
  HiOutlineUserCircle,
  HiPlusCircle,
} from 'react-icons/hi';
import { useBoolean, useEffectOnce, useReadLocalStorage } from 'usehooks-ts';

import { rudderstackTrackEvent } from '@/api/rudderstack/client';
import { CSRLazy } from '@/lib/CSRLazy';
import {
  LoadHeavyContentAtom,
  pageLoading,
  stickyBannerAtom,
} from '@/lib/store';
import useLoginHook from '@/modules/auth/hooks/useLoginHook';
import Button, { ButtonIconPosition } from '@/tayara-kit/Button';
import Divider, { Orientation } from '@/tayara-kit/Divider';
import { TkT } from '@/tayara-kit/icons';
import ProfileAvatar from '@/tayara-kit/ProfileAvatar';
import { NEXT_API_URL } from 'environment';

import { NavbarSearchInput } from './LargeSearchInput/NavbarSearchInput';
import NavbarUpper from './NavbarUpper';
import { AuthTokenAtom } from '../store/grpc-free-store';

const Notification = dynamic(async () => import('@/firebase/Notification'), {
  ssr: false,
});
const Modernizr = { backdropfilter: true };

const Navbar = () => {
  // @ts-ignore
  const [authToken] = useAtom(AuthTokenAtom);
  const headless = useReadLocalStorage('headless');
  // const authToken = useReadLocalStorage<string>('auth_token');
  // @ts-ignore
  const current = useReadLocalStorage('userid')?.toString();
  // const [, setConnected] = useState(false);
  // const centrifuge = new Centrifuge(environment.livechat);
  const expiredIn = useReadLocalStorage<number>('expires_in');
  const [, setCountUnreadMsg] = useState(0);
  const router = useRouter();
  const headlessParam = router.query.headless;
  const { pathname } = useRouter();
  // const [loadHeavyContent] = useAtom(LoadHeavyContentAtom);
  const [showStickyBanner] = useAtom(stickyBannerAtom);
  const {
    // value: showSearchBar,
    setTrue: setShowSearchBarToTrue,
    // setFalse: setShowSearchBarToFalse,
  } = useBoolean(false);
  useEffectOnce(() => {
    if (current) {
      fetch(`${NEXT_API_URL}/marketplace/get_unread_discussions_count/`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: authToken,
          userRequest: {
            kcid: current,
          },
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setCountUnreadMsg(res?.count);
        });
    }
  });

  const isAuthenticated = useMemo(
    () => Date.now() / 1000 < +(expiredIn || 0),
    [expiredIn]
  );
  const { logout } = useLoginHook();

  const [scrollDirection, setScrollDirection] = useState('none');
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  // const [navbarUpperVisible, setNavbarUpperVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPosition = window.pageYOffset;

    // Determine scrolling direction
    if (currentScrollPosition > lastScrollPosition) {
      setScrollDirection('down');
    } else if (currentScrollPosition < lastScrollPosition) {
      setScrollDirection('up');
    }

    // Update last scroll position
    setLastScrollPosition(currentScrollPosition);

    // Show/hide NavbarUpper based on scrolling direction
    if (scrollDirection === 'up') {
      // setNavbarUpperVisible(true); // Scrolling up, show the NavbarUpper
    }
  };

  useEffect(() => {
    if (headlessParam === 'true') {
      if (window !== undefined) {
        localStorage.setItem('headless', 'true');
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollDirection, lastScrollPosition]);
  const isImmoNeuf =
    router.pathname
      .split('/')
      .some((segment) => segment.toLowerCase() === 'immoneuf') ||
    router?.query?.category === 'ImmoNeuf' ||
    router?.query.slug?.includes('Immobilier Neuf');
  const [isLoading] = useAtom(pageLoading);

  return (
    <>
      <CSRLazy fallback={''}>
        {LoadHeavyContentAtom && showStickyBanner ? <Notification /> : ''}
      </CSRLazy>
      {!headless ? (
        <div className="w-full flex flex-col fixed z-[9999] h-fit top-0  ">
          <NavbarUpper />
          <div
            className={`pointer-events-none absolute w-full lg:w-2/12 left-1/2 -translate-x-1/2 bottom-[-42px] border-t-[1px] border-t-primary transition-transform duration-300 ${
              isLoading ? 'scale-y-100' : 'scale-y-0 translate-y-[-18px]'
            }`}
          >
            <img
              className="mx-auto mt-[3.65px] flex-none scale-125"
              src="/media/loader.svg"
              alt="Logo de tayara.tn"
            />
          </div>
          <div
            className={`hidden md:flex justify-center items-center w-full mx-auto  border-b-[1px] border-neutral-200 h-[100px] py-4 px-auto bg-white`}
          >
            <div className="w-full flex justify-between items-center max-w-[1920px] md:px-16">
              {/* Left navigation items */}
              <div className="w-fit flex justify-start items-center  h-[4rem]">
                {/* Brand / logo */}

                <Link
                  href={{ pathname: '/' }}
                  passHref
                  className="flex justify-between items-center"
                >
                  <img
                    className={`min-w-[70px] max-w-[120px] transition-all duration-300 hidden xl:block`}
                    src={
                      isImmoNeuf
                        ? '/media/immoxxLogo.png'
                        : '/media/tayara-logo.svg'
                    }
                    alt="Logo de tayara.tn"
                  />
                </Link>

                <Link href={{ pathname: '/' }} passHref>
                  <TkT
                    className="py-2 text-primary hidden md:block xl:hidden"
                    size={48}
                  />
                </Link>
              </div>
              {
                // router.pathname !== '/' &&
                // !router.pathname.startsWith('/ads') &&
                // !router.pathname.startsWith('/search') &&
                // <SearchInput navbar />

                <NavbarSearchInput />
              }
              {/* Right navigation items */}
              <div className="hidden md:flex w-fit justify-end items-center ">
                {/* This will be reactivated in a later version */}
                {/* <button className="text-neutral-500 focus:rotate-45 transition-transform">
      <HiCog size={22} />
    </button> */}

                {/* <RatingMenu position={RatingMenuPosition.Left} /> */}
                {/* <Divider orientation={Orientation.VERTICAL} /> */}

                <span
                  className={`flex rounded-xl ${
                    isImmoNeuf
                      ? 'bg-neuf-100 hover:bg-neuf-200'
                      : 'bg-primary hover:bg-primary-focus'
                  } `}
                >
                  <Link
                    passHref
                    href={'/post-listing'}
                    aria-label="Publier annonce"
                    onClick={() =>
                      rudderstackTrackEvent(
                        'Clicked on listing insertion button'
                      )
                    }
                    className="flex items-center p-2 text-white"
                  >
                    <HiPlusCircle size={22} className={'mx-3'} />

                    <h3 className="pr-3 pb-[1px] text-white text-sm font-semibold hidden md:hidden xl:block w-max">
                      Publier une annonce
                    </h3>
                  </Link>
                </span>

                <Divider
                  orientation={Orientation.VERTICAL}
                  className={'!h-5'}
                />
                {isAuthenticated ? (
                  <ProfileAvatar logout={logout} />
                ) : (
                  <Link href={{ pathname: '/login' }} replace passHref>
                    <button
                      aria-label="Se connecter"
                      className="flex items-center p-2 rounded-xl hover:bg-neutral-200 border border-neutral-300 border-opacity-75 text-neutral-500 text-sm  font-bold font-arabic hover:!border-solid "
                    >
                      <HiOutlineUserCircle size={22} className={'mx-3'} />
                      <h3 className="text-neutral-500  text-sm pr-3 font-semibold block md:hidden xl:block w-max">
                        Se connecter
                      </h3>
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
          {/* South side actions (lower Navbar) */}

          <div
            className={`md:hidden !z-[9998] flex w-full flex-col border-t border-gray-200 shadow-sm fixed bottom-0 left-0 right-0 transition-transform duration-700 
${Modernizr.backdropfilter ? 'bg-white/80 bg-blend-overlay' : 'bg-white'}`}
            style={{
              backdropFilter: 'blur(24px)',
              WebkitBackdropFilter: 'blur(24px)',
            }}
          >
            <div className={`w-full font-arabic grid grid-cols-5 my-2`}>
              <Link
                href={{
                  pathname: '/shops',
                }}
                passHref
              >
                <Button
                  aria-label="Mes recherches sauvegardes"
                  icon={<BiStore size={24} className="my-1" />}
                  iconPosition={ButtonIconPosition.Top}
                  className={`line-clamp-1 w-full rounded-none font-normal text-2xs !bg-transparent ${
                    pathname.indexOf('/shops') === 0
                      ? 'text-primary'
                      : 'text-neutral-500'
                  }`}
                >
                  Boutiques
                </Button>
              </Link>
              <Link
                className="flex justify-center"
                href={{ pathname: '/immoNeuf' }}
                shallow
                passHref
              >
                <Button
                  aria-label="ImmoNeuf"
                  onClick={setShowSearchBarToTrue}
                  icon={<AiOutlineHome size={24} className="my-1" />}
                  iconPosition={ButtonIconPosition.Top}
                  className={`line-clamp-1 w-full rounded-none font-normal text-2xs !bg-transparent !truncate ${
                    pathname.indexOf('/immoNeuf') === 0
                      ? 'text-primary'
                      : 'text-neutral-500'
                  }`}
                >
                  ImmoNeuf
                </Button>
              </Link>

              <Link
                className="flex justify-center"
                href={{ pathname: '/post-listing' }}
                shallow
                passHref
              >
                <Button
                  aria-label="Postuler annonce"
                  icon={<HiOutlinePlus size={24} />}
                  iconPosition={ButtonIconPosition.Top}
                  className={`absolute top-1 !justify-start !items-start !mx-2 line-clamp-1 !rounded-xl !p-2 !shadow-primary !shadow-lg !bg-primary w-fit font-normal text-2xs ${
                    pathname.indexOf('/post-listing') === 0
                      ? 'text-white !outline-double !outline-offset-2 !outline-2 !outline-primary'
                      : 'text-white'
                  }`}
                >
                  Annonce
                </Button>
              </Link>
              <Link href={{ pathname: '/chat' }} shallow passHref>
                <Button
                  aria-label="Consulter les messages"
                  icon={<BiMessageAlt size={24} className="my-1" />}
                  iconPosition={ButtonIconPosition.Top}
                  className={`line-clamp-1 w-full rounded-none font-normal text-2xs !bg-transparent ${
                    pathname.indexOf('/chat') === 0
                      ? 'text-primary'
                      : 'text-neutral-500'
                  }`}
                >
                  Messages
                </Button>
              </Link>
              <Link
                href={{ pathname: isAuthenticated ? '/profile' : '/login' }}
                shallow
                passHref
              >
                <Button
                  aria-label="Profil"
                  icon={<AiOutlineUser size={24} className="my-1" />}
                  iconPosition={ButtonIconPosition.Top}
                  className={`line-clamp-1 w-full rounded-none font-normal text-2xs !bg-transparent ${
                    pathname.indexOf('/profile') === 0
                      ? 'text-primary'
                      : 'text-neutral-500'
                  }`}
                >
                  Profil
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Navbar;
