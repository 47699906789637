import React, {
  Dispatch,
  FormEventHandler,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Transition } from '@headlessui/react';
import { SetStateAction } from 'jotai';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FaSearch } from 'react-icons/fa';
import { HiArrowNarrowRight, HiChevronUp } from 'react-icons/hi';
import { IoFilterCircleOutline } from 'react-icons/io5';
import { useBoolean, useOnClickOutside, useWindowSize } from 'usehooks-ts';

import { GoogleAdsTargeting } from '@/lib/hooks/useGoogleAdsTrageting';
import Divider, { Orientation } from '@/tayara-kit/Divider';

import SearchHistoryStrip from './SearchHistoryStrip';
import ShowToRight from '../../animations/ShowToRight';
import { useElasticSearchSearchInput } from '../../hooks/useElasticSearchServer';
import useFocus from '../../hooks/useFocus';
import FrequentlySearchedKeywordsStrip from '../FrequentlySearchedKeywordsStrip';
import KeyCategories from '../KeyCategories';
import RecentClassifiedsList from '../RecentClassifiedsList/RecentClassifiedsList';

export interface NavbarSearchInputProps
  extends React.HTMLAttributes<HTMLInputElement> {
  /**
   * Optional click handler
   */
  onClick?: () => void;
  setSearchFocused?: Dispatch<SetStateAction<boolean>>;
  setSearchHasValue?: Dispatch<SetStateAction<boolean>>;
  iconClasses?: string;
}

/**
 * Home page search field
 */
export const NavbarSearchInput: React.FC<NavbarSearchInputProps> = ({
  className,
  setSearchFocused,
  setSearchHasValue,
  iconClasses,
  ...props
}) => {
  // const [
  //   searchQuery,
  //   setSearchQuery,
  //   initSearchWithQuery,
  //   handleSearchInputOnBlur,
  // ] = useMeilisearchSearchInput();

  const [
    searchQuery,
    setSearchQuery,
    initSearchWithQuery,
    handleSearchInputOnBlur,
  ] = useElasticSearchSearchInput();

  const [targetingValue, setTargetingValue] = useState(searchQuery);

  const [hasValue, setHasValue] = React.useState(false);

  const handleSearchQuery = (value: string) => {
    setSearchQuery(value);
    setHasValue(value !== '');
  };
  const router = useRouter();
  const isImmoNeuf =
    router.pathname
      .split('/')
      .some((segment) => segment.toLowerCase() === 'immoneuf') ||
    router?.query?.category === 'ImmoNeuf' ||
    router?.query.slug?.includes('Immobilier Neuf');
  const {
    setTrue: setAdvancedOn,
    setFalse: setAdvancedOff,
    value: advancedOpen,
  } = useBoolean(false);
  const panelRef = useRef<HTMLDivElement>(null);
  const closeRef = useRef<HTMLButtonElement>(null);
  const [searchInputRef, { hasFocus: inputHasFocus, ref: inputRef }] =
    useFocus<HTMLInputElement>();

  useEffect(() => {
    if (setSearchFocused) setSearchFocused(inputHasFocus);
    if (setSearchHasValue) setSearchHasValue(hasValue);
  }, [inputHasFocus, setSearchFocused, hasValue, setSearchHasValue]);

  // If input has a value but onChange was not emitted
  useEffect(() => {
    if (inputRef && inputRef.value !== '') {
      setHasValue(true);
    }
  }, [inputRef, inputRef?.value, setHasValue]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setAdvancedOff();
    setTargetingValue(searchQuery);
    if (inputRef) inputRef.blur();
    if (initSearchWithQuery) initSearchWithQuery(e);
  };

  useOnClickOutside(panelRef, setAdvancedOff);
  const [panelRect, setPanelRect] = useState<DOMRect>();
  useEffect(() => {
    setPanelRect(panelRef.current?.getBoundingClientRect());
  }, [advancedOpen]);

  const { width: windowSize } = useWindowSize();

  const panelState = useRef({ translateY: 0, travel: 0, initialY: 0 });
  const handleTouchMove = (event: React.TouchEvent<HTMLButtonElement>) => {
    const touch = event.touches[0];
    if (touch) {
      if (panelState.current.initialY === 0) {
        panelState.current.initialY = touch?.screenY;
      }
      if (panelRef.current) {
        panelRef.current.style.transform = `translateY(${panelState.current.translateY}px)`;
      }

      panelState.current.travel = panelRect
        ? -panelRect.top + touch.screenY - panelState.current.initialY
        : touch.screenY - panelState.current.initialY;

      panelState.current.translateY = panelState.current.travel;
    }
  };

  const handleTouchEnd = () => {
    if (Math.abs(panelState.current.travel) > 200) {
      panelState.current = { translateY: 0, travel: 0, initialY: 0 };
      setAdvancedOff();
      return;
    }
    panelState.current = { translateY: 0, travel: 0, initialY: 0 };
    if (panelRect && panelRef.current) {
      panelRef.current.style.transform = `translateY(${-panelRect.top}px)`;
    }
    setAdvancedOn();
  };
  // const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handelKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setAdvancedOff();
        if (inputRef) inputRef.blur();
      }
    };
    document.addEventListener('keydown', handelKeyDown);

    return () => {
      document.removeEventListener('keydown', handelKeyDown);
    };
  }, [setAdvancedOff, inputRef]);

  return (
    <div
      className={`mx-10 relative transition-all outline-4 outline-offset-2 outline-neutral-400 ${
        windowSize < 1920 ? 'w-full' : 'w-[44rem] ml-72'
      }`}
      ref={panelRef}
    >
      <form onSubmit={handleSubmit} className="w-full h-[4rem]">
        <GoogleAdsTargeting q={targetingValue} />
        <input
          onFocus={setAdvancedOn}
          ref={searchInputRef}
          {...props}
          className={`z-[9999] px-[100px] pb-4 w-full h-full text-base cursor-text flex border-[1px]  text-neutral-600 rounded-3xl outline-none   
        ${
          advancedOpen
            ? 'shadow-sm shadow-neutral-400 border-neutral-300 outline-offset-0 rounded-3xl'
            : 'shadow-sm shadow-neutral-400 border-neutral-200'
        }  outline-1 
            placeholder:text-neutral-500/80  lg:placeholder:text-base transition-all duration-600 focus:transition-all
              font-extrabold font-arabic  hover:outline-none ${className}`}
          placeholder="Rechercher sur tayara"
          value={searchQuery}
          onChange={(e) => handleSearchQuery(e.target.value)}
          onBlur={handleSearchInputOnBlur}
        />
        {/* Layer on top of the search input */}
        <span
          className={`w-full absolute inset-0 flex items-center justify-center pointer-events-none px-10`}
        >
          {/* West side */}
          <span className="w-full flex ">
            <div
              className={`w-10 h-10 flex items-center justify-center my-auto rounded-full ${
                isImmoNeuf ? 'bg-neuf-100' : 'bg-primary'
              }`}
            >
              <FaSearch className={`text-white m-3`} size={18} />
            </div>

            <div className=" w-full">
              <div className="pt-6 pl-5 w-full">
                <SearchHistoryStrip
                  onSelect={(value) => handleSearchQuery(value)}
                />
              </div>
            </div>
          </span>

          {/* East side */}
          <div className="w-fit py-2 absolute right-10 justify-end flex flex-col truncat">
            {/* Go/search button */}
            <div className="w-full flex items-center justify-end h-full bg-white">
              <Link
                href={
                  isImmoNeuf
                    ? '/ads/c/ImmoNeuf/Immobilier%20Neuf/?page=1'
                    : '/search'
                }
                passHref
                className={`w-fit ${
                  isImmoNeuf ? 'text-neuf-100' : 'text-primary'
                }  pointer-events-auto text-xs font-semibold flex items-center gap-x-1 p-1 rounded-md `}
              >
                <IoFilterCircleOutline
                  size={24}
                  className={` ${
                    isImmoNeuf ? 'text-neuf-100' : 'text-primary'
                  } truncate`}
                />
                Filtre avancé
              </Link>
              <ShowToRight isShowing={hasValue}>
                <button
                  className={`flex items-center justify-center p-2  rounded-lg pointer-events-auto text-white space-x-1 ${
                    isImmoNeuf ? 'text-neuf-100' : 'text-primary'
                  }   m-2 hover:bg-primary-focus`}
                >
                  <HiArrowNarrowRight size={22} />
                </button>
              </ShowToRight>
            </div>
          </div>

          {/* Backdrop overlay */}
          <Transition
            show={advancedOpen}
            as={Fragment}
            enter="transition-opacity duration-150"
            leave="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed top-36 right-0 w-full h-screen z-[9997] bg-white/50 backdrop-blur-md"
              onClick={setAdvancedOff} // Close the advanced search when clicking outside
            />
          </Transition>

          {/* Advanced search interface layer */}
          <Transition
            show={advancedOpen}
            as={Fragment}
            enter="transition-all duration-150"
            leave="transition-all duration-200"
            enterFrom="scale-y-0 origin-top opacity-0 translate-y-[-50px]"
            enterTo="scale-y-100 opacity-100 origin-top -translate-y-0"
            leaveFrom="scale-y-100 opacity-100 origin-top -translate-y-0"
            leaveTo="scale-y-0 origin-top opacity-0 translate-y-[-50px]"
          >
            <div
              className={`fixed z-[9999] inset-0 mx-20 h-fit top-32 bg-white pointer-events-auto rounded-2xl border-[1px] border-neutral-200  pt-6 mt-2 shadow-2xl`}
            >
              <div className="flex items-stretch flex-col gap-2 min-w-full mt-6 lg:mt-0 pb-8 px-10">
                <RecentClassifiedsList topOffset={70} />
                <Divider
                  className="!mt-3 !mb-0 !bg-neutral-100"
                  orientation={Orientation.HORIZONTAL}
                />
                <FrequentlySearchedKeywordsStrip />
                <Divider
                  className="!mt-3 !mb-0 !bg-neutral-100"
                  orientation={Orientation.HORIZONTAL}
                />

                <div className="mx-0 mt-3">
                  <KeyCategories setAdvancedOff={setAdvancedOff} />
                </div>
              </div>
              <button
                type="button"
                onClick={setAdvancedOff}
                className={`text-white bg-primary hover:bg-primary-focus w-full text-base font-bold pointer-events-auto py-2 rounded-b-2xl mt-3 flex justify-center items-center gap-x-1 touch-none`}
                ref={closeRef}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                <HiChevronUp size={22} />
                Fermer
              </button>
            </div>
          </Transition>
        </span>
      </form>
    </div>
  );
};
