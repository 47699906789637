import React, { useEffect, useRef, useState } from 'react';

import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { useWindowSize } from 'usehooks-ts';

import { APP_CONFIG } from '@/app-config';
import { pageLoading } from '@/lib/store';
import { Meta } from '@/seo/Meta';
import Breadcrumbs from '@/tayara-kit/Breadcrumbs';
import Button from '@/tayara-kit/Button';
import Text from '@/tayara-kit/Text';

import Footer from './Footer';
import Navbar from './Navbar';
import useResizeObserver from '../hooks/useResizeObserver';

// const lg = 1024;

interface LayoutProps {
  children: React.ReactNode;
}

interface WithSidePanelProps {
  aside: JSX.Element;
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  // const windowSize = useWindowSize();
  const { asPath } = useRouter();
  // const [isLoading] = useAtom(pageLoading);
  return (
    <>
      <Meta
        title={APP_CONFIG.title}
        description={APP_CONFIG.description}
        canonical={`${APP_CONFIG.basePath}${asPath}`}
        altLangs={[
          {
            href: `${APP_CONFIG.basePath}/ar-TN`,
            hrefLang: 'ar-TN',
          },
          {
            href: `${APP_CONFIG.basePath}/fr`,
            hrefLang: 'fr',
          },
          {
            href: `${APP_CONFIG.basePath}`,
            hrefLang: 'en',
          },
          {
            href: `${APP_CONFIG.basePath}/ar`,
            hrefLang: 'ar',
          },
        ]}
      />

      <Navbar />

      {children}
    </>
  );
};

const WithSidePanel = ({ aside, children }: WithSidePanelProps) => {
  const { back } = useRouter();
  const [isLoading] = useAtom(pageLoading);
  const windowSize = useWindowSize();
  const [asideRef, { width: asideWidth }] = useResizeObserver();
  const [asideRec, setAsideRec] = useState<{
    pageOffsetTop: number;
    width: number;
  }>();

  useEffect(() => {
    const box = {
      pageOffsetTop: asideRef.current?.offsetTop || 64,
      width: asideWidth || 300,
    };
    setAsideRec(box);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asideWidth, setAsideRec, windowSize.width]);

  const mainRef = useRef<HTMLDivElement>(null);
  const asidePositionRef = useRef(0);
  useEffect(() => {
    const handleScroll = () => {
      if (mainRef.current && asideRef.current && windowSize.width > 1024) {
        const mainHeight = mainRef.current.offsetHeight;
        const mainOffsetTop = mainRef.current.offsetTop;
        const mainBottom = mainOffsetTop + mainHeight;
        const currentScroll = window.scrollY + window.innerHeight;

        // Calculate how much we need to translate the aside
        const translateY = Math.max(0, currentScroll - mainBottom);

        // Apply transform to the aside
        asideRef.current.style.transform = `translateY(-${translateY}px)`;
        asidePositionRef.current = translateY;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [asideRef]);

  return (
    <>
      <div
        className={`flex flex-col xl:flex-row h-fit w-full overflow-x-hidden ${
          isLoading ? 'mt-[13rem] lg:mt-[9rem]' : 'mt-[13rem] lg:mt-[9rem]'
        }  max-w-[1920px]  lg:px-12 mx-auto`}
      >
        <aside
          ref={asideRef}
          className={`lg:fixed w-fit lg:flex flex-col relative lg:z-10 md:z-10 xl:z-10 `}
          style={{
            ...(windowSize.width > 1024
              ? { height: `calc(100vh - ${asideRec?.pageOffsetTop}px)` }
              : { width: '100vw' }),
          }}
        >
          {/* Back button and breadcrumbs */}
          <div className="hidden max-w-min w-full grow-0 lg:hidden items-center justify-center lg:justify-between h-34 py-1 px-3 md:px-3 lg:px-4">
            <Button
              aria-label="Retour"
              onClick={back}
              className=" btn-outline btn-sm text-2xs font-normal text-gray-700 bg-gray-100 border-gray-300
            hover:bg-gray-100 hover:text-gray-800 hover:border-gray-500 min-w-max"
              icon={<HiArrowNarrowLeft size={16} />}
            >
              <Text text="Retour" className="" />
            </Button>
            <span className="hidden">
              <Breadcrumbs />
            </span>
          </div>
          <div className="grow overflow-y-hidden ">{aside}</div>
        </aside>
        <main
          ref={mainRef}
          className="overflow-x-hidden pr-0 lg:pr-4 z-[1] flex-1"
          style={{
            ...(windowSize.width > 1024
              ? { marginLeft: `${asideRec?.width}px` }
              : {}),

            width:
              windowSize.width > 1024
                ? `calc(100% - ${asideRec?.width}px)`
                : `100%`,
          }}
        >
          {children}
        </main>
      </div>
      <Footer />
    </>
  );
};

const Wide = ({ children }: LayoutProps) => {
  const router = useRouter();
  // const [isLoading] = useAtom(pageLoading);

  return (
    <>
      <main
        // className={`w-full max-w-[1920px] mx-auto mt-[11rem] md:mt-[145px] mb-10 md:mb-20 lg:mb-10 overflow-hidden px-4 md:px-16 `}
        className={`w-full max-w-[1920px]  md:px-4 mx-auto  overflow-hidden mt-[152px] md:mt-[146px] ${
          // router.pathname === '/services' ||
          router.pathname === '/bons-plans-tayara' || router.pathname === '/'
            ? '!p-0 !mb-0'
            : 'px-2 md:px-12 lg:px-14'
        }
        
          `}
      >
        {children}
      </main>
      {router.pathname !== '/post-listing/' && <Footer />}
    </>
  );
};

Layout.WithSidePanel = WithSidePanel;
Layout.Wide = Wide;
export default Layout;

// import React, { useEffect, useState, useRef } from 'react';
// import { useAtom } from 'jotai';
// import { useRouter } from 'next/router';
// import { HiArrowNarrowLeft } from 'react-icons/hi';
// import { useWindowSize } from 'usehooks-ts';

// import { APP_CONFIG } from '@/app-config';
// import { pageLoading } from '@/lib/store';
// import { Meta } from '@/seo/Meta';
// import Breadcrumbs from '@/tayara-kit/Breadcrumbs';
// import Button from '@/tayara-kit/Button';
// import Text from '@/tayara-kit/Text';

// import Footer from './Footer';
// import Navbar from './Navbar';
// import useResizeObserver from '../hooks/useResizeObserver';

// interface LayoutProps {
//   children: React.ReactNode;
// }

// interface WithSidePanelProps {
//   aside: JSX.Element;
//   children: React.ReactNode;
// }

// const Layout = ({ children }: LayoutProps) => {
//   const { asPath } = useRouter();
//   return (
//     <>
//       <Meta
//         title={APP_CONFIG.title}
//         description={APP_CONFIG.description}
//         canonical={`${APP_CONFIG.basePath}${asPath}`}
//         altLangs={[
//           {
//             href: `${APP_CONFIG.basePath}/ar-TN`,
//             hrefLang: 'ar-TN',
//           },
//           {
//             href: `${APP_CONFIG.basePath}/fr`,
//             hrefLang: 'fr',
//           },
//           {
//             href: `${APP_CONFIG.basePath}`,
//             hrefLang: 'en',
//           },
//           {
//             href: `${APP_CONFIG.basePath}/ar`,
//             hrefLang: 'ar',
//           },
//         ]}
//       />

//       <Navbar />

//       {children}
//     </>
//   );
// };

// const WithSidePanel = ({ aside, children }: WithSidePanelProps) => {
//   const { back } = useRouter();
//   const [isLoading] = useAtom(pageLoading);
//   const windowSize = useWindowSize();
//   const [asideRef, { width: asideWidth }] = useResizeObserver();
//   const [asideRec, setAsideRec] = useState<{
//     pageOffsetTop: number;
//     width: number;
//   }>();
//   useEffect(() => {
//     const box = {
//       pageOffsetTop: asideRef.current?.offsetTop || 64,
//       width: asideWidth || 300,
//     };
//     setAsideRec(box);
//   }, [asideWidth, windowSize.width]);

//   const mainRef = useRef<HTMLDivElement>(null);
//   const asidePositionRef = useRef(0);
//   useEffect(() => {
//     const handleScroll = () => {
//       if (mainRef.current && asideRef.current) {
//         const mainHeight = mainRef.current.offsetHeight;
//         const mainOffsetTop = mainRef.current.offsetTop;
//         const mainBottom = mainOffsetTop + mainHeight;
//         const currentScroll = window.scrollY + window.innerHeight;

//         // Calculate how much we need to translate the aside
//         const translateY = Math.max(0, currentScroll - mainBottom);

//         // Apply transform to the aside
//         asideRef.current.style.transform = `translateY(-${translateY}px)`;
//         asidePositionRef.current = translateY;
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [asideRef]);

//   return (
//     <>
//       <div
//         className={`relative flex flex-col xl:flex-row h-full w-full overflow-x-hidden ${
//           isLoading ? 'mt-[13rem] lg:mt-[9rem]' : 'mt-[13rem] lg:mt-[9rem]'
//         } max-w-[1920px] lg:px-12 mx-auto`}
//       >
//         <aside
//           ref={asideRef}
//           className="w-fit lg:flex flex-col lg:fixed lg:top-[9rem] lg:z-10 md:z-10 xl:z-10"
//           style={{

//             height: windowSize.width > 1024 ? `calc(100vh - ${asideRec?.pageOffsetTop}px)`: ''

//           }}
//         >
//           {/* Back button and breadcrumbs */}
//           <div className="hidden max-w-min w-full grow-0 lg:hidden items-center justify-center lg:justify-between h-34 py-1 px-3 md:px-3 lg:px-4">
//             <Button
//               aria-label="Retour"
//               onClick={back}
//               className="btn-outline btn-sm text-2xs font-normal text-gray-700 bg-gray-100 border-gray-300 hover:bg-gray-100 hover:text-gray-800 hover:border-gray-500 min-w-max"
//               icon={<HiArrowNarrowLeft size={16} />}
//             >
//               <Text text="Retour" className="" />
//             </Button>
//             <span className="hidden">
//               <Breadcrumbs />
//             </span>
//           </div>
//           <div className="grow overflow-y-hidden">{aside}</div>
//         </aside>
//         <main
//           ref={mainRef}
//           className="overflow-x-hidden pr-0 lg:pr-4 z-[1] flex-1"
//           style={{
//             ...(windowSize.width > 1024
//               ? { marginLeft: `${asideRec?.width}px` }
//               : {}),

//             width:
//               windowSize.width > 1024
//                 ? `calc(100% - ${asideRec?.width}px)`
//                 : `100%`,
//           }}
//         >
//           {children}
//         </main>
//       </div>
//       <Footer />
//     </>
//   );
// };

// const Wide = ({ children }: LayoutProps) => {
//   const router = useRouter();

//   return (
//     <>
//       <main
//         className={`w-full max-w-[1920px]  md:px-4 mx-auto  overflow-hidden mt-[152px] md:mt-[146px] ${
//           router.pathname === '/bons-plans-tayara' || router.pathname === '/'
//             ? '!p-0 !mb-0'
//             : 'px-2 md:px-12 lg:px-14'
//         }`}
//       >
//         {children}
//       </main>
//       {router.pathname !== '/post-listing/' && <Footer />}
//     </>
//   );
// };

// Layout.WithSidePanel = WithSidePanel;
// Layout.Wide = Wide;
// export default Layout;
