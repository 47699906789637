import React, { useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaYoutubeSquare,
} from 'react-icons/fa';
import { HiPhone } from 'react-icons/hi';
import { useEffectOnce, useReadLocalStorage } from 'usehooks-ts';

import {
  CategoryHelper,
  getAllParentCategories,
  getAllSubCategoriesAndGroupByParentId,
} from '@/lib/helpers';
import TForTayara from '@/tayara-kit/icons/TForTayara';
import Text from '@/tayara-kit/Text';

interface CompleteCategory extends CategoryHelper {
  subCategories: CategoryHelper[];
}

const Footer = () => {
  const router = useRouter();
  const headless = useReadLocalStorage('headless');

  const [categoriesMap, setCategoriesMap] = useState<CompleteCategory[]>([]);
  useEffectOnce(() => {
    const parentCategories = getAllParentCategories();
    const childCategories = getAllSubCategoriesAndGroupByParentId();

    const fusion: CompleteCategory[] = parentCategories.map(
      (cat) =>
        ({
          ...cat,
          subCategories: childCategories[cat.id],
        } as CompleteCategory)
    );
    setCategoriesMap(fusion);
  });

  const HELP_ITEMS = [
    {
      text: 'Contactez-nous',
      link: '/contact/',
      anchorProps: {
        target: '_blank',
      },
    },
    {
      text: 'Professionnels',
      link: { pathname: '/leads/tayara-pro/' },
      anchorProps: {
        target: '_blank',
      },
    },
    {
      text: "Conditions d'utilisation",
      link: { pathname: '/terms/' },
      anchorProps: {
        target: '_blank',
      },
    },
    {
      text: 'Politiques relatives aux cookies',
      link: {
        pathname: '/terms/',
        hash: '#Utilisation des cookies et autres technologies',
      },
      anchorProps: {
        target: '_blank',
      },
    },
  ];
  const others = [
    { text: 'Publier une annonce', link: { pathnname: '/post-listing' } },
    { text: 'Visiter une boutique', link: { pathnname: '/shops' } },
    // { text: 'Votre profil public', link: { isAuthenticated ? pathnname: '/something' } : pathname: '/login' },
    { text: 'Filtres avancés', link: { pathnname: '/search' } },
    { text: 'Rechercher sur tayara', link: { pathnname: '/search' } },
    { text: 'Offres et services', link: { pathnname: '/services' } },
    { text: 'Bons plans', link: { pathnname: '/bons-plans-tayara' } },
  ];
  if (headless) return <></>;

  return (
    <div className="w-full bg-gray-100 ">
      <div
        className={`max-w-[1920px] flex flex-col mx-auto justify-center min-h-[700px] w-full bg-gray-100 py-16  !z-[9998] relative bottom-0 ${
          router.pathname === '/services' || router.pathname === '/'
            ? 'mt-0'
            : 'mt-0'
        } `}
      >
        {/* Main footer links */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 mx-4 md:mx-16 gap-y-12">
          <div className="flex flex-col gap-y-6">
            <Link href="/" passHref className="block">
              <img
                loading="lazy"
                src="/media/tayara-logo.svg"
                className="w-44"
                alt="tayara logo in SVG format"
              />
            </Link>
            <p className="font-semibold italic text-gray-600 max-w-[230px]">
              Acheter et vendre gratuitement près de chez vous
            </p>

            <a
              href="/immoNeuf"
              target="_blank"
              className="px-3 py-1 bg-neuf-100 text-white font-extrabold rounded-full mr-auto mt-auto"
              rel="noreferrer"
            >
              Immo-Expert
            </a>
          </div>
          <div className="space-y-3">
            <h4 className="font-extrabold text-gray-700 text-2xl">Aide</h4>
            <ul className="flex flex-col gap-y-1">
              {HELP_ITEMS.map((help, index) => (
                <li key={index}>
                  <Link
                    href={help.link}
                    passHref
                    className="text-sm text-gray-600"
                    target={help.anchorProps.target}
                  >
                    {help.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="space-y-3">
            <h4 className="font-extrabold text-gray-700 text-2xl">
              Raccourcis
            </h4>
            <ul className="flex flex-col gap-y-1">
              {others.map((help, index) => (
                <li key={index}>
                  <Link
                    href={help.link.pathnname}
                    passHref
                    className="text-sm text-gray-600"
                  >
                    {help.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h4 className="font-extrabold text-gray-700 text-2xl">
              Contactez-nous
            </h4>
            <p className="text-sm text-gray-600 mt-2">
              Vous avez une question?
              <br /> appelez ce numéro
            </p>
            <ul className="flex flex-col gap-y-1 mt-3 text-sm font-bold text-gray-600">
              <li className="flex gap-x-1 items-center">
                <HiPhone />
                <span className="flex items-baseline gap-x-1">
                  <span className="text-2xs">+216</span> 95 256 096
                </span>
              </li>
            </ul>
          </div>
        </div>

        {/* Cagegories and sub categories map */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-8 border-y-[1px] border-gray-300 mt-12 bg-gray-200/50 p-4 md:p-16 py-12">
          {categoriesMap.map((parent) => (
            <div key={parent.name} className="space-y-1">
              <h2>
                <Link
                  href={{
                    pathname: 'search',
                    query: {
                      category: parent.id,
                    },
                  }}
                  passHref
                  className="font-bold text-gray-700"
                >
                  {parent.name}
                </Link>
              </h2>
              <ul>
                {parent.subCategories?.map((child) => (
                  <li key={child.id}>
                    <Link
                      href={{
                        pathname: 'search',
                        query: {
                          category: parent.name,
                          subCategory: child.name,
                        },
                      }}
                      passHref
                      className="text-xs text-gray-600"
                    >
                      {child.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Footer of the footer */}
        <div className="px-10 py-16 flex text-gray-700">
          <div className="m-auto flex flex-col gap-y-8">
            <TForTayara className="mx-auto" size={60} />
            <div className="flex flex-col flex-wrap justify-center items-center gap-x-8 gap-y-4">
              <Text
                className="text-sm md:text-base font-light italic"
                text="Retrouvez-nous sur"
              />
              <div className="flex mt-2 md:mt-0 justify-center items-center gap-x-4">
                <Link
                  className="text-gray-700"
                  passHref
                  href="https://www.facebook.com/tayara.tn"
                  target={'_blank'}
                >
                  <FaFacebookSquare size={35} />
                </Link>
                <Link
                  className="text-gray-700"
                  passHref
                  href="https://www.instagram.com/tayara.tn/"
                  target={'_blank'}
                >
                  <FaInstagramSquare size={35} />
                </Link>
                <Link
                  className="text-gray-700"
                  passHref
                  href="https://tn.linkedin.com/company/tayara-tn"
                  target={'_blank'}
                >
                  <FaLinkedin size={35} />
                </Link>{' '}
                <Link
                  className="text-gray-700"
                  passHref
                  href="https://www.youtube.com/@TayaraTV"
                  target={'_blank'}
                >
                  <FaYoutubeSquare size={36} />
                </Link>
              </div>
            </div>
            <div
              className="font-extrabold font-arabic text-2xl flex gap-x-2 items-center justify-center md:mr-5"
              dir="rtl"
            >
              <p className="flex flex-col sm:flex-row gap-y-1 sm:gap-8 font-arabic">
                <span className="text-center sm:hidden">•</span>{' '}
                <span className="text-center">صوّر بيع</span>{' '}
                <span className="text-center hidden sm:inline-table">•</span>{' '}
                <span className="text-center">لوّج إشري</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
