import React, { useState } from 'react';

import {
  IoBriefcaseOutline,
  IoCarSportOutline,
  IoFootballOutline,
  IoPhonePortraitOutline,
  IoShirtOutline,
} from 'react-icons/io5';
import { MdOutlineRealEstateAgent } from 'react-icons/md';
import { RiBuilding2Line, RiHomeHeartLine } from 'react-icons/ri';
import { useEffectOnce } from 'usehooks-ts';

import {
  CategoriesGroup,
  getAllSubCategoriesAndGroupByParentId,
} from '@/lib/helpers';

import KeyCategoryItem from './KeyCategoryItem';
import { useElasticSearchKeyCategories } from '../hooks/useElasticSearchServer';

const KEY_CATEGORIES = [
  {
    label: 'Véhicules',
    icon: IoCarSportOutline,
    link: 'vehicles',
    linkFr: 'Véhicules',
    id: '60be84bc50ab95b45b08a094',
  },
  {
    label: 'Immobilier',
    icon: MdOutlineRealEstateAgent,
    link: 'real-estate',
    linkFr: 'Immobilier',
    id: '60be84bc50ab95b45b08a093',
  },
  {
    label: 'Multimédias',
    icon: IoPhonePortraitOutline,
    link: 'tech',
    linkFr: 'Informatique et Multimedias',
    id: '60be84bc50ab95b45b08a097',
  },
  {
    label: 'Maison & jardin',
    icon: RiHomeHeartLine,
    link: 'home-and-garden',
    linkFr: 'Pour la Maison et Jardin',
    id: '60be84bc50ab95b45b08a095',
  },
  {
    label: 'Loisirs',
    icon: IoFootballOutline,
    link: 'leisure-and-entertainment',
    linkFr: 'Loisirs et Divertissement',
    id: '60be84bc50ab95b45b08a096',
  },
  {
    label: 'Habillement',
    icon: IoShirtOutline,
    link: 'clothing-and-well-being',
    linkFr: 'Habillement et Bien Etre',
    id: '60be84bc50ab95b45b08a099',
  },
  {
    label: 'Emplois',
    icon: IoBriefcaseOutline,
    link: 'jobs-and-services',
    linkFr: 'Emploi et Services',
    id: '60be84bc50ab95b45b08a098',
  },
  {
    label: 'Entreprises',
    icon: RiBuilding2Line,
    link: 'Entreprises',
    linkFr: 'Entreprises',
    id: '60be84bd50ab95b45b08a09a',
  },
];

const KeyCategories = ({
  setAdvancedOff,
}: {
  setAdvancedOff: (value: boolean) => void;
}) => {
  const [handleCategoryClick] = useElasticSearchKeyCategories();

  const handleClick = (selectedCategoryLink: string) => {
    handleCategoryClick(selectedCategoryLink);
    setAdvancedOff(false);
  };

  const [groupedCategories, setGroupedCategories] = useState<CategoriesGroup>();
  useEffectOnce(() => {
    setGroupedCategories(getAllSubCategoriesAndGroupByParentId());
  });

  return (
    <ul className="grid grid-cols-4 gap-6 md:flex flex-wrap justify-center mx-auto max-w-xs md:max-w-2xl px-2">
      {KEY_CATEGORIES.map((data) => (
        <li key={data.link}>
          <KeyCategoryItem
            subCategories={groupedCategories?.[data.id] || []}
            handleCategoryClick={() => handleClick(data.link)}
            categoryData={data}
          />
        </li>
      ))}
    </ul>
  );
};

export default KeyCategories;
